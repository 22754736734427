<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <Header :firstCourse="first_course" :classroom="classroom"></Header>
      </div>
      <div class="col-md-6">
        <CourseInfo :firstCourse="first_course"></CourseInfo>
        <LessonContent :firstCourse="first_course"></LessonContent>
        <TeacherCourse v-if="classroom && classroom.teacher"
                       :teacher="classroom.teacher"
                       :classroom="classroom">
        </TeacherCourse>
        <RateAndComment :ratings="ratings"></RateAndComment>
      </div>
      <div class="col-md-6 col-xs-12">
        <ContractRegister
            :firstCourse="first_course"
            :classroom="classroom"
            @openDetail="openDetailStudent"
        ></ContractRegister>
      </div>
    </div>
    <el-dialog @closed="dialogTableVisible = false" title="Danh sách học viên" :visible.sync="dialogTableVisible">
      <dialog-sudent :students="students"></dialog-sudent>
    </el-dialog>
  </div>
</template>

<script>
import {TU_VAN_DETAIL_COURSE} from "@/core/services/store/tuVan.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@/assets/css/tuvanvien.scss';
import Header from "../course/components/Header";
import CourseInfo from "./components/CourseInfo";
import LessonContent from "./components/LessonContent";
import TeacherCourse from "./components/TeacherCourse";
import RateAndComment from "./components/RateAndComment";
import ContractRegister from "./components/ContractRegister";
import DialogSudent from "./components/DialogSudent";

export default {
  name: "DetailCourse",
  components: {DialogSudent, ContractRegister, RateAndComment, TeacherCourse, LessonContent, CourseInfo, Header},
  data() {
    return {
      ratings: [],
      course_ratings: [],
      first_course: {},
      classroom: {},
      teacher: {},
      activeName: 'first',
      loading: false,
      students: {},
      dialogTableVisible: false,
    };
  },
  mounted() {
    this.retrieveCourseDetail();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chi tiết khóa học", icon: ''}
    ]);
  },
  methods: {
    openDetailStudent(students) {
      this.dialogTableVisible = true;
      this.students = students;
    },
    retrieveCourseDetail() {
      let payload = {
        course_id: this.$route.params.course_id,
        customer_id: this.$route.query.customer_id,
        branch_id: this.$route.query.branch_id,
        type: this.$route.query.type,
      };
      this.loading_course = true;
      this.$store.dispatch(TU_VAN_DETAIL_COURSE, payload)
          .then((response) => {
            this.course_ratings = response.data.course_ratings;
            this.ratings = response.data.ratings;
            this.first_course = response.data.firstCourse;
            this.classroom = response.data.classroom;
            this.teacher = response.data.firstClassroom && response.data.firstClassroom.teacher ? response.data.firstClassroom.teacher : {};
            this.loading_course = false;
          });
    }
  },
}
</script>

<style>

</style>